import store from "..";
import router from "@/router";
import UserModule from "../module/user/UserModule";
import AdminModule from "../module/admin/AdminModule";
import { routePath } from "@/router/router-constants";
import Notificator from "./Notificator";
export default class ApiErrorHandler {
    static onServerError(status, method, payload = null) {
        const errorMessage = {
            base: "<ApiErrorHandler> Receieved backend http server error.",
            meta: `Status: ${status}. Method: ${method}`,
            payload: payload ? `Payload: \n${JSON.stringify(payload, null, 2)}` : "",
        };
        console.error(`${errorMessage.base}\n${errorMessage.meta}\n${errorMessage.payload}`);
        new Notificator().notify({
            title: "Сервис временно недоступен.",
            text: "Попробуйте позже, или обратитесь к администратору.",
            type: "error",
            duration: 4000,
        });
        throw new Error(`<ApiErrorHandler> Backend HTTP Server error`);
    }
    static async onBussinesLogicError(status, method, payload, response) {
        const errorMessage = {
            base: "<ApiErrorHandler> Receieved backend http business logic error.",
            meta: `Status: ${status}. Method: ${method}`,
            payload: payload ? `Payload: \n${JSON.stringify(payload, null, 2)}` : "",
        };
        console.error(`${errorMessage.base}\n${errorMessage.meta}\n${errorMessage.payload}`);
        const data = await response.json();
        if (response.status === 401) {
            store.dispatch(UserModule.types.actions.LOGOUT);
            store.dispatch(AdminModule.types.actions.LOGOUT);
            router.push(routePath.LOGIN);
            throw new Error(`<ApiErrorHandler> Backend HTTP Unathorized error. Redirect to login page.`);
        }
        new Notificator().notify({
            title: data.error,
            type: "error",
            duration: 4000,
        });
        throw new Error(`<ApiErrorHandler> Backend HTTP Business logic error`);
    }
    static onServerUnavailable() {
        new Notificator().notifyDeduplicated({
            title: "Сервис временно недоступен.",
            text: "Попробуйте позже, или обратитесь к администратору.",
            type: "error",
            duration: 5000,
        });
        throw new Error(`<ApiErrorHandler> Backend unavailable`);
    }
}
