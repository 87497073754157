import moment from "moment";
import DateValueObject from "./DateValueObject";
export default class Timestamp extends DateValueObject {
    toFirstDayInMonth() {
        return new Timestamp(this.moment(this.moment(this.value).format("YYYY-MM-01 00:00:00")).toDate());
    }
    getMonth() {
        return this.moment(this.value).month() + 1;
    }
    getYear() {
        return this.moment(this.value).year();
    }
    format(format) {
        return this.moment(this.value).format(format);
    }
    static now() {
        return new Timestamp(new Date());
    }
    static createFirstDayInMonth(year, month) {
        return new Timestamp(moment(moment(`${year}-${moment(month, "M").format("MM")}-01 00:00:00`)).toDate());
    }
    static createFromString(dateString) {
        return new Timestamp(moment(dateString).toDate());
    }
    static diff(endTimestamp, startTimestamp, units) {
        return moment(endTimestamp.value).diff(startTimestamp.value, units);
    }
    static add(timestamp, duration, unit) {
        return new Timestamp(moment(timestamp.value).add(duration, unit).toDate());
    }
    copy() {
        return new Timestamp(this.value);
    }
}
