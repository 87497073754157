import { __decorate } from "tslib";
import ApiRequest from "../../shared/ApiRequest";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
let GroupModule = class GroupModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.apiRoute = {
            ROOT_FACTORY: (qs) => `/groups?${qs}`,
            ROOT_ID_FACTORY: (id) => `/groups/${id}`,
            ROOT: `/groups`,
            AVATAR: "/groups/avatar",
            PARTICIPANTS: "/groups/participants",
            PHOTOS: "/groups/photos",
            PHOTOS_ID_FACTORY: (id) => `/groups/photos/${id}`,
            DOCUMENTS: "/groups/documents",
            DOCUMENTS_ID_FACTORY: (id) => `/groups/documents/${id}`,
        };
        this.$groupsTable = {};
        this.$groups = [];
        this.UPDATE_GROUP_DIRECTLY_MUTATION = "updateGroupDirectlyMutation";
        this.APPEND_GROUP_MUTATION = "appendGroupMutation";
        this.DELETE_GROUP_BY_ID_MUTATION = "deleteGrouByIdMutation";
        this.SET_GROUPS_MUTATION = "setGroupsMutation";
        this.UPDATE_GROUP_NUTATION = "updateGroupMutation";
        this.APPEND_PARTICIPANTS_MUTATION = "appendParticipantsMutation";
        this.REMOVE_PARTICIPANTS_MUTATION = "removeParticipantsMutation";
        this.REMOVE_GROUP_PHOTO_MUTATION = "removeGroupPhotoMutation";
        this.APPEND_GROUP_PHOTOS_MUTATION = "appendGroupPhotosMutation";
        this.REMOVE_GROUP_DOCUMENT_MUTATION = "removeGroupDocumentMutation";
        this.APPEND_GROUP_DOCUMENTS_MUTATION = "appendGroupDocumentsMutation";
    }
    get groups() {
        return this.$groups;
    }
    get groupsTable() {
        return this.$groupsTable;
    }
    updateGroupDirectlyMutation(data) {
        const { group } = data;
        this.$groupsTable[group.id].fullName = group.fullName;
        this.$groupsTable[group.id].shortName = group.shortName;
        this.$groupsTable[group.id].code = group.shortName;
        this.$groupsTable[group.id].executiveId = group.executiveId;
        this.$groupsTable[group.id].description = group.description;
        this.$groupsTable[group.id].isPremoderated = group.isPremoderated;
        if (group.executive !== undefined) {
            this.$groupsTable[group.id].executive = group.executive;
        }
        if (group.contacts !== undefined) {
            this.$groupsTable[group.id].contacts = group.contacts;
        }
        if (group.participants !== undefined) {
            this.$groupsTable[group.id].participants = group.participants;
        }
        if (group.photos !== undefined) {
            this.$groupsTable[group.id].photos = group.photos;
        }
        if (group.documents !== undefined) {
            this.$groupsTable[group.id].documents = group.documents;
        }
    }
    appendGroupMutation(data) {
        const { group } = data;
        this.$groups.push(group);
        this.$groupsTable[group.id] = group;
    }
    deleteGrouByIdMutation(data) {
        const { id } = data;
        delete this.$groupsTable[id];
        const index = this.$groups.findIndex((item) => item.id === id);
        if (index !== -1) {
            this.$groups.splice(index, 1);
        }
    }
    setGroupsMutation(data) {
        const { groups } = data;
        this.$groups = groups;
        this.$groupsTable = {};
        for (let group of this.$groups) {
            if (Object.prototype.hasOwnProperty.call(this.$groupsTable, group.id) === false) {
                this.$groupsTable[group.id] = group;
            }
        }
    }
    updateGroupMutation(data) {
        const { group } = data;
        const index = this.$groups.findIndex((g) => g.id === group.id);
        this.$groups[index] = group;
        this.$groupsTable[group.id] = this.$groups[index];
    }
    appendParticipantsMutation(data) {
        data.participants.map((p) => {
            this.$groupsTable[p.groupId].participants.push(p);
        });
    }
    removeParticipantsMutation(data) {
        const participantsTable = new Map();
        this.$groupsTable[data.groupId].participants.map((participant, index) => {
            participantsTable.set(participant.id, index);
        });
        for (const id of data.participantsIds) {
            if (participantsTable.has(id)) {
                const index = participantsTable.get(id);
                this.$groupsTable[data.groupId].participants.splice(index, 1);
            }
        }
    }
    removeGroupPhotoMutation(data) {
        const index = this.$groupsTable[data.groupId].photos.findIndex((photo) => photo.id === data.photoId);
        if (index === -1)
            return;
        this.$groupsTable[data.groupId].photos.splice(index, 1);
    }
    appendGroupPhotosMutation(data) {
        if (this.$groupsTable[data.groupId].photos === undefined || this.$groupsTable[data.groupId].photos === null) {
            this.$groupsTable[data.groupId].photos = [];
        }
        if (this.$groupsTable[data.groupId].photos.length > 0) {
            this.$groupsTable[data.groupId].photos.splice(0);
        }
        for (let photo of data.photos) {
            this.$groupsTable[data.groupId].photos.push(photo);
        }
    }
    removeGroupDocumentMutation(data) {
        const index = this.$groupsTable[data.groupId].documents.findIndex((document) => document.id === data.documentId);
        if (index === -1)
            return;
        this.$groupsTable[data.groupId].documents.splice(index, 1);
    }
    appendGroupDocumentsMutation(data) {
        if (this.$groupsTable[data.groupId].documents === undefined || this.$groupsTable[data.groupId].documents === null) {
            this.$groupsTable[data.groupId].documents = [];
        }
        if (this.$groupsTable[data.groupId].documents.length > 0) {
            this.$groupsTable[data.groupId].documents.splice(0);
        }
        for (let doc of data.documents) {
            this.$groupsTable[data.groupId].documents.push(doc);
        }
    }
    rerenderGroups() {
        console.log("groups re-render triggered");
        this.context.commit(this.SET_GROUPS_MUTATION, { groups: Object.assign([], this.$groups) });
    }
    async getGroups(data = {}) {
        const qs = ApiRequest.getQueryString(data);
        const response = await ApiRequest.json(this.apiRoute.ROOT_FACTORY(qs), "GET");
        this.context.commit(this.SET_GROUPS_MUTATION, { groups: response });
        return response;
    }
    async loadGroup(data) {
        return ApiRequest.json(this.apiRoute.ROOT_ID_FACTORY(data.id), "GET");
    }
    async addGroup(data) {
        const response = await ApiRequest.json(this.apiRoute.ROOT, "POST", data);
        this.context.commit(this.APPEND_GROUP_MUTATION, { group: response });
        return response;
    }
    async updateGroup(data) {
        const response = await ApiRequest.json(this.apiRoute.ROOT_ID_FACTORY(data.id), "PUT", { group: data.group });
        this.context.commit(this.UPDATE_GROUP_DIRECTLY_MUTATION, { group: response });
        return response;
    }
    async deleteGroup(data) {
        const response = await ApiRequest.json(this.apiRoute.ROOT_ID_FACTORY(data.id), "DELETE");
        this.context.commit(this.DELETE_GROUP_BY_ID_MUTATION, data);
        return response;
    }
    async addParticipants(data) {
        const response = await ApiRequest.json(this.apiRoute.PARTICIPANTS, "POST", {
            participants: data.participants,
        });
        this.context.commit(this.APPEND_PARTICIPANTS_MUTATION, { groupId: data.groupId, participants: response });
        return response;
    }
    async deleteParticipants(data) {
        const response = await ApiRequest.json(this.apiRoute.PARTICIPANTS, "DELETE", {
            participantsIds: data.participantsIds,
        });
        this.context.commit(this.REMOVE_PARTICIPANTS_MUTATION, data);
        return response;
    }
    async uploadAvatar(data) {
        return ApiRequest.upload(this.apiRoute.AVATAR, data.fd);
    }
    async uploadPhotos(data) {
        const response = await ApiRequest.upload(this.apiRoute.PHOTOS, data.fd);
        this.context.commit(this.APPEND_GROUP_PHOTOS_MUTATION, { groupId: data.fd.get("groupId"), photos: response });
        return response;
    }
    async uploadDocuments(data) {
        const response = await ApiRequest.upload(this.apiRoute.DOCUMENTS, data.fd);
        this.context.commit(this.APPEND_GROUP_DOCUMENTS_MUTATION, {
            groupId: data.fd.get("groupId"),
            documents: response,
        });
        return response;
    }
    async deletePhoto(data) {
        const response = await ApiRequest.json(this.apiRoute.PHOTOS_ID_FACTORY(data.groupId), "DELETE", {
            photoId: data.photoId,
        });
        this.context.commit(this.REMOVE_GROUP_PHOTO_MUTATION, data);
        return response;
    }
    async deleteDocument(data) {
        const response = await ApiRequest.json(this.apiRoute.DOCUMENTS_ID_FACTORY(data.groupId), "DELETE", {
            documentId: data.documentId,
        });
        this.context.commit(this.REMOVE_GROUP_DOCUMENT_MUTATION, data);
        return response;
    }
};
GroupModule.types = {
    getters: {
        GROUPS: "Group/groups",
        GROUPS_TABLE: "Group/groupsTable",
    },
    actions: {
        RE_RENDER_GROUPS: "Group/rerenderGroups",
        GET_GROUPS: "Group/getGroups",
        LOAD_GROUP: "Group/loadGroup",
        ADD_GROUP: "Group/addGroup",
        UPDATE_GROUP: "Group/updateGroup",
        DELETE_GROUP: "Group/deleteGroup",
        ADD_PARTICIPANTS: "Group/addParticipants",
        DELETE_PARTICIPANTS: "Group/deleteParticipants",
        UPLOAD_AVATAR: "Group/uploadAvatar",
        UPLOAD_PHOTOS: "Group/uploadPhotos",
        UPLOAD_DOCUMENTS: "Group/uploadDocuments",
        DELETE_PHOTO: "Group/deletePhoto",
        DELETE_DOCUMENT: "Group/deleteDocument",
    },
};
__decorate([
    Mutation
], GroupModule.prototype, "updateGroupDirectlyMutation", null);
__decorate([
    Mutation
], GroupModule.prototype, "appendGroupMutation", null);
__decorate([
    Mutation
], GroupModule.prototype, "deleteGrouByIdMutation", null);
__decorate([
    Mutation
], GroupModule.prototype, "setGroupsMutation", null);
__decorate([
    Mutation
], GroupModule.prototype, "updateGroupMutation", null);
__decorate([
    Mutation
], GroupModule.prototype, "appendParticipantsMutation", null);
__decorate([
    Mutation
], GroupModule.prototype, "removeParticipantsMutation", null);
__decorate([
    Mutation
], GroupModule.prototype, "removeGroupPhotoMutation", null);
__decorate([
    Mutation
], GroupModule.prototype, "appendGroupPhotosMutation", null);
__decorate([
    Mutation
], GroupModule.prototype, "removeGroupDocumentMutation", null);
__decorate([
    Mutation
], GroupModule.prototype, "appendGroupDocumentsMutation", null);
__decorate([
    Action
], GroupModule.prototype, "rerenderGroups", null);
__decorate([
    Action
], GroupModule.prototype, "getGroups", null);
__decorate([
    Action
], GroupModule.prototype, "loadGroup", null);
__decorate([
    Action
], GroupModule.prototype, "addGroup", null);
__decorate([
    Action
], GroupModule.prototype, "updateGroup", null);
__decorate([
    Action
], GroupModule.prototype, "deleteGroup", null);
__decorate([
    Action
], GroupModule.prototype, "addParticipants", null);
__decorate([
    Action
], GroupModule.prototype, "deleteParticipants", null);
__decorate([
    Action
], GroupModule.prototype, "uploadAvatar", null);
__decorate([
    Action
], GroupModule.prototype, "uploadPhotos", null);
__decorate([
    Action
], GroupModule.prototype, "uploadDocuments", null);
__decorate([
    Action
], GroupModule.prototype, "deletePhoto", null);
__decorate([
    Action
], GroupModule.prototype, "deleteDocument", null);
GroupModule = __decorate([
    Module({ namespaced: true })
], GroupModule);
export default GroupModule;
