export default class TTLMap {
    constructor(entries) {
        this.ttls = new Map();
        this.map = new Map(entries);
    }
    set(key, value, ttl = null) {
        if (ttl === null) {
            this.map.set(key, value);
            return;
        }
        if (this.ttls.has(key)) {
            this.ttls.delete(key);
            clearTimeout(this.ttls.get(key));
        }
        this.map.set(key, value);
        this.ttls.set(key, setTimeout(this.delete.bind(this), ttl, key));
    }
    delete(key) {
        const timeout = this.ttls.get(key);
        if (timeout) {
            clearTimeout(this.ttls.get(key));
        }
        this.ttls.delete(key);
        return this.map.delete(key);
    }
    get(key) {
        return this.map.get(key);
    }
    clear(key) {
        this.ttls.clear();
        return this.map.clear();
    }
    entries() {
        return this.map.entries();
    }
    keys() {
        return this.map.keys();
    }
    values() {
        return this.map.values();
    }
    forEach(callbackfn, thisArg) {
        return this.map.forEach.apply(this.map, [callbackfn, thisArg]);
    }
    has(key) {
        return this.map.has(key);
    }
    size() {
        return this.map.size;
    }
    [Symbol.iterator](...args) {
        return this.map[Symbol.iterator].apply(this.map, arguments);
    }
}
