export const ONLY_AUTHORIZED = "onlyAuthorized";
export const ONLY_ADMIN = "onlyAdmin";
export const ONLY_USER = "onlyUser";
export const routePath = {
    ROOT: "/",
    LOGIN: "/login",
    admin: {
        ROOT: "/admin",
        USERS: "/admin/users",
        EVENTS: "/admin/events",
        EVENT: "/admin/events/:id",
        EVENT_FACTORY: (id) => `/admin/events/${id}`,
        GROUPS: "/admin/groups",
        GROUP: "/admin/groups/:id",
        GROUP_FACTORY: (id) => `/admin/groups/${id}`,
    },
    user: {
        ROOT: "/user",
        EVENTS: "/user/events",
        EVENT: "/user/events/:id",
        GROUPS: "/user/groups",
        GROUP: "/user/groups/:id",
        PROFILE: "/user/profile",
        SETTINGS: "/user/settings",
    },
    public: {
        ROOT: "/public",
        EVENT: "/public/events/:id"
    }
};
