import AuthorizationType from "./value-object/AuthorizationType";
import Timestamp from "@/shared/domain/value-object/Timestamp";
import AuthorizationToken from "./value-object/AuthorizationToken";
export default class Credentials {
    constructor(type, token, expiresIn) {
        this.type = type;
        this.token = token;
        this.expiresIn = expiresIn;
    }
    static fromPrimitives(_raw) {
        return new Credentials(new AuthorizationType(_raw.type), new AuthorizationToken(_raw.token), new Timestamp(_raw.expiresIn));
    }
    static fromLocalStorage() {
        const raw = localStorage.getItem(Credentials.CREDENTIALS_LOCAL_STORAGE_KEY);
        if (raw === null)
            return null;
        const appRaw = JSON.parse(raw);
        const credentials = Credentials.fromPrimitives(appRaw);
        if (credentials.expiresIn.isBefore(Timestamp.now())) {
            credentials.cleanFromLocalStorage();
            return null;
        }
        return credentials;
    }
    isExpired() {
        return this.expiresIn.isBefore(Timestamp.now());
    }
    saveToLocalStorage() {
        localStorage.setItem(Credentials.CREDENTIALS_LOCAL_STORAGE_KEY, JSON.stringify(this.toPrimitives()));
    }
    cleanFromLocalStorage() {
        localStorage.removeItem(Credentials.CREDENTIALS_LOCAL_STORAGE_KEY);
    }
    toPrimitives() {
        return {
            type: this.type.value,
            token: this.token.value,
            expiresIn: this.expiresIn.value,
        };
    }
}
Credentials.CREDENTIALS_LOCAL_STORAGE_KEY = "$my.archery/credentials";
