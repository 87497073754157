import { __decorate } from "tslib";
import ApiRequest from "../../shared/ApiRequest";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import Credentials from "@/store/shared/Credentials";
import AuthorizationType from "@/store/shared/value-object/AuthorizationType";
import AuthorizationToken from "@/store/shared/value-object/AuthorizationToken";
import Timestamp from "@/shared/domain/value-object/Timestamp";
import Waiter from "@/helpers/Waiter";
let AdminModule = class AdminModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.apiRoute = {
            ADMIN: "/admin",
            LOGIN: "/admin/login",
        };
        this.$credentials = Credentials.fromLocalStorage();
        this.$admin = null;
        this.SET_CREDENTIALS_MUTATION = "setCredentialsMutation";
        this.LOGOUT_MUTATION = "logoutMutation";
        this.SET_ADMIN_MUTATION = "setAdminMutation";
    }
    get credentials() {
        return this.$credentials;
    }
    get admin() {
        return this.$admin;
    }
    get isSuperAdmin() {
        if (!this.$admin)
            return false;
        return this.$admin.isSuperAuth;
    }
    get isRegionalAdmin() {
        if (!this.$admin)
            return false;
        return !this.$admin.isSuperAuth;
    }
    setCredentialsMutation(data) {
        this.$credentials = new Credentials(AuthorizationType.ADMIN(), new AuthorizationToken(data.token), Timestamp.add(Timestamp.now(), 1, "month"));
        this.$credentials.saveToLocalStorage();
    }
    logoutMutation() {
        this.$credentials?.cleanFromLocalStorage();
        this.$credentials = null;
    }
    setAdminMutation(data) {
        this.$admin = data.admin;
    }
    async waitForAdminLoading() {
        if (!this.$admin) {
            await Waiter.wait(500);
            return this.context.dispatch("waitForAdminLoading");
        }
        return;
    }
    async getAdmin() {
        if (!this.$credentials) {
            throw new Error(`<${this.constructor.name}> Trying load admin without credentials`);
        }
        const response = await ApiRequest.json(this.apiRoute.ADMIN, "GET");
        this.context.commit(this.SET_ADMIN_MUTATION, { admin: response });
        return response;
    }
    async login(data) {
        const response = await ApiRequest.json(this.apiRoute.LOGIN, "POST", data);
        this.context.commit(this.SET_CREDENTIALS_MUTATION, { token: response.accessToken });
        this.context.commit(this.SET_ADMIN_MUTATION, { admin: response.admin });
        return response;
    }
    async logout() {
        this.context.commit(this.LOGOUT_MUTATION);
        this.context.commit(this.SET_ADMIN_MUTATION, { admin: null });
    }
};
AdminModule.types = {
    getters: {
        CREDENTIALS: "Admin/credentials",
        ADMIN: "Admin/admin",
        IS_SUPER_ADMIN: "Admin/isSuperAdmin",
        IS_REGIONAL_ADMIN: "Admin/isRegionalAdmin",
    },
    actions: {
        GET_ADMIN: "Admin/getAdmin",
        LOGIN: "Admin/login",
        LOGOUT: "Admin/logout",
        WAIT_FOR_ADMIN_LOADING: "Admin/waitForAdminLoading",
    },
    mutations: {},
};
__decorate([
    Mutation
], AdminModule.prototype, "setCredentialsMutation", null);
__decorate([
    Mutation
], AdminModule.prototype, "logoutMutation", null);
__decorate([
    Mutation
], AdminModule.prototype, "setAdminMutation", null);
__decorate([
    Action
], AdminModule.prototype, "waitForAdminLoading", null);
__decorate([
    Action
], AdminModule.prototype, "getAdmin", null);
__decorate([
    Action
], AdminModule.prototype, "login", null);
__decorate([
    Action
], AdminModule.prototype, "logout", null);
AdminModule = __decorate([
    Module({ namespaced: true })
], AdminModule);
export default AdminModule;
