import Vue from "vue";
import Vuex from "vuex";
import AdminModule from "./module/admin/AdminModule";
import UsersModule from "./module/admin/UsersModule";
import HandbookModule from "./module/shared/HandbookModule";
import EventModule from "./module/shared/EventModule";
import GroupModule from "./module/shared/GroupModule";
import UserModule from "./module/user/UserModule";
Vue.use(Vuex);
export default new Vuex.Store({
    state: {
    // isAuthorized: false,
    },
    getters: {
    // isAuthorized(state) {
    //   return state.isAuthorized
    // },
    },
    mutations: {
    // setAuthorizationStatusMutation(state, status: boolean) {
    //   state.isAuthorized = status
    // },
    },
    actions: {
    // async checkout(context) {
    //   const status = await Request.checkout()
    //   if (status) {
    //     context.commit("setAuthorizationStatusMutation", true)
    //   } else {
    //     context.dispatch(AdminModule.types.actions.LOGOUT)
    //     context.dispatch(UserModule.types.actions.LOGOUT)
    //     context.commit("setAuthorizationStatusMutation", false)
    //   }
    // },
    },
    modules: {
        Admin: AdminModule,
        Users: UsersModule,
        Handbook: HandbookModule,
        Event: EventModule,
        Group: GroupModule,
        User: UserModule,
    },
});
