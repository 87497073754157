import moment from "moment";
import InvalidArgumentError from "../error/InvalidArgumentError";
import ValueObject from "./ValueObject";
export default class DateValueObject extends ValueObject {
    constructor(value) {
        super(value);
        this.value = value;
        this.moment = moment;
        this.ensureIsValidDate();
    }
    ensureIsValidDate() {
        if (!moment(this.value, true).isValid()) {
            throw new InvalidArgumentError(`<${this.constructor.name}> <${this.value}> is not valid date`);
        }
    }
    equals(other) {
        return this.constructor.name === other.constructor.name && this.moment(this.value).isSame(moment(other.value));
    }
    isBefore(dateValueObject) {
        return moment(this.value).isBefore(dateValueObject.value);
    }
    isSameOrAfter(dateValueObject) {
        return moment(this.value).isSameOrAfter(moment(dateValueObject.value));
    }
    isAfter(dateValueObject) {
        return moment(this.value).isAfter(moment(dateValueObject.value));
    }
}
