import { ONLY_ADMIN, ONLY_AUTHORIZED, ONLY_USER, routePath } from "./router-constants";
export const routes = [
    {
        path: routePath.LOGIN,
        name: "login",
        meta: {
            [ONLY_AUTHORIZED]: false,
        },
        component: () => import("@/pages/shared/Auth.vue"),
    },
    {
        path: "/",
        meta: {
            [ONLY_AUTHORIZED]: true,
        },
    },
    {
        path: routePath.public.ROOT,
        name: "public-root",
        meta: {
            [ONLY_AUTHORIZED]: false,
            [ONLY_ADMIN]: false,
            [ONLY_USER]: false,
        },
        component: () => import("@/views/public/PublicView.vue"),
        children: [
            {
                path: routePath.public.EVENT,
                name: "public-event",
                meta: {
                    [ONLY_AUTHORIZED]: false,
                    [ONLY_ADMIN]: false,
                    [ONLY_USER]: false,
                },
                component: () => import("@/pages/public/EventPage.vue"),
            },
        ],
    },
    {
        path: routePath.admin.ROOT,
        name: "admin-root",
        component: () => import("@/views/admin/AdminDashboard.vue"),
        meta: {
            [ONLY_AUTHORIZED]: true,
            [ONLY_ADMIN]: true,
        },
        children: [
            {
                path: routePath.admin.USERS,
                name: "admin-users",
                meta: {
                    [ONLY_AUTHORIZED]: true,
                    [ONLY_ADMIN]: true,
                },
                component: () => import("@/pages/admin/UsersPage.vue"),
            },
            {
                path: routePath.admin.EVENTS,
                name: "admin-events",
                meta: {
                    [ONLY_AUTHORIZED]: true,
                    [ONLY_ADMIN]: true,
                },
                component: () => import("@/pages/admin/EventsPage.vue"),
            },
            {
                path: routePath.admin.EVENT,
                name: "admin-event",
                meta: {
                    [ONLY_AUTHORIZED]: true,
                    [ONLY_ADMIN]: true,
                },
                component: () => import("@/pages/admin/EventPage.vue"),
            },
            {
                path: routePath.admin.GROUPS,
                name: "admin-groups",
                meta: {
                    [ONLY_AUTHORIZED]: true,
                    [ONLY_ADMIN]: true,
                },
                component: () => import("@/pages/admin/GroupsPage.vue"),
            },
            {
                path: routePath.admin.GROUP,
                name: "admin-group",
                meta: {
                    [ONLY_AUTHORIZED]: true,
                    [ONLY_ADMIN]: true,
                },
                component: () => import("@/pages/admin/GroupPage.vue"),
            },
        ],
    },
    {
        path: routePath.user.ROOT,
        name: "user-root",
        meta: {
            [ONLY_AUTHORIZED]: true,
            [ONLY_USER]: true,
        },
        component: () => import("@/views/user/UserDashboard.vue"),
        children: [
            {
                path: routePath.user.ROOT,
                name: "user-page",
                meta: {
                    [ONLY_AUTHORIZED]: true,
                    [ONLY_USER]: true,
                },
                component: () => import("@/pages/user/UserPage.vue"),
            },
            {
                path: routePath.user.EVENTS,
                name: "user-events",
                meta: {
                    [ONLY_AUTHORIZED]: true,
                    [ONLY_USER]: true,
                },
                component: () => import("@/pages/user/EventsPage.vue"),
            },
            {
                path: routePath.user.EVENT,
                name: "user-event",
                meta: {
                    [ONLY_AUTHORIZED]: true,
                    [ONLY_USER]: true,
                },
                component: () => import("@/pages/user/EventPage.vue"),
            },
            {
                path: routePath.user.GROUPS,
                name: "user-groups",
                meta: {
                    [ONLY_AUTHORIZED]: true,
                    [ONLY_USER]: true,
                },
                component: () => import("@/pages/user/GroupsPage.vue"),
            },
            {
                path: routePath.user.GROUP,
                name: "user-group",
                meta: {
                    [ONLY_AUTHORIZED]: true,
                    [ONLY_USER]: true,
                },
                component: () => import("@/pages/user/GroupPage.vue"),
            },
            {
                path: routePath.user.PROFILE,
                name: "user-profile",
                meta: {
                    [ONLY_AUTHORIZED]: true,
                    [ONLY_USER]: true,
                },
                component: () => import("@/pages/user/UserProfilePage.vue"),
            },
            {
                path: routePath.user.SETTINGS,
                name: "user-settings",
                meta: {
                    [ONLY_AUTHORIZED]: true,
                    [ONLY_USER]: true,
                },
                component: () => import("@/pages/user/Settings.vue"),
            },
        ],
    },
];
// children: [
//   {
//     path: "/users",
//     name: "Члены РФСЛ",
//     component: () => import("@/pages/UsersPage.vue"),
//     meta: {
//       [ONLY_AUTHORIZED]: true,
//       [ONLY_ADMIN]: true,
//     },
//   },
//   {
//     path: "/user/:id",
//     name: "Страница пользователя",
//     component: () => import("@/pages/UserPage.vue"),
//     meta: {
//       [ONLY_AUTHORIZED]: true,
//       [ONLY_USER]: true,
//     },
//   },
//   {
//     path: "/events",
//     name: "События",
//     component: () => import("@/pages/EventsPage.vue"),
//   },
//   {
//     path: "/event/:id",
//     name: "Страница события",
//     component: () => import("@/pages/EventPage.vue"),
//   },
//   {
//     path: "/groups",
//     name: "Спорт. школы/Клубы",
//     component: () => import("@/pages/GroupsPage.vue"),
//   },
//   {
//     path: "/group/:id",
//     name: "Страница организации",
//     component: () => import("@/pages/GroupPage.vue"),
//   },
//   {
//     path: "/settings",
//     name: "Настройки",
//     component: () => import("@/pages/Settings.vue"),
//   },
//   {
//     path: "/profile",
//     name: "Профиль",
//     component: () => import("@/pages/UserProfilePage.vue"),
//   },
// ],
