import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
let App = class App extends Vue {
    async mounted() { }
};
App = __decorate([
    Component({
        components: {},
    })
], App);
export default App;
