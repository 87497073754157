export default class Waiter {
    static wait(timeout) {
        return new Promise((resolve) => setTimeout(resolve, timeout));
    }
    static async waitForRender(refs, ref, count = 1) {
        if (!refs[ref]) {
            await Waiter.wait(100);
            return this.waitForRender(refs, ref, count);
        }
        if (count > 1 && refs[ref].length < count) {
            await Waiter.wait(100);
            return this.waitForRender(refs, ref, count);
        }
        return;
    }
}
