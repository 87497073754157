import { __decorate } from "tslib";
import ApiRequest from "../../shared/ApiRequest";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import Credentials from "@/store/shared/Credentials";
import AuthorizationType from "@/store/shared/value-object/AuthorizationType";
import AuthorizationToken from "@/store/shared/value-object/AuthorizationToken";
import Timestamp from "@/shared/domain/value-object/Timestamp";
import Waiter from "@/helpers/Waiter";
let UserModule = class UserModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.apiRoute = {
            ROOT: "/user",
            LOGIN: "/user/login",
            REGISTER_EXISTED: "/user/register-existed",
            SEND_EMAIL_CODE: "/user/email-send-code",
            VERIFY_EMAIL_CODE: "/user/email-verify-code",
            PASSWORD_CHECKOUT_FACTORY: (qs) => `/user/password-checkout?${qs}`,
            EXISTED_BY_FACTORY: (qs) => `/user/existed-by?${qs}`,
            FIND_BY_FACTORY: (qs) => `/user/find-by?${qs}`,
            UPDATE_REQUEST: "/user/update-request",
            AVATAR: "/user/avatar",
            RELATED_REGION_PROFILED: "/user/related-region-profiled",
            RELATED_REGION: "/user/related-region",
        };
        this.$credentials = Credentials.fromLocalStorage();
        this.$user = null;
        this.SET_CREDENTIALS_MUTATION = "setCredentialsMutation";
        this.LOGOUT_MUTATION = "logoutMutation";
        this.SET_USER_MUTATION = "setUserMutation";
    }
    get credentials() {
        return this.$credentials;
    }
    get user() {
        return this.$user;
    }
    get isCoach() {
        return this.$user && this.$user.profile && !!this.$user.profile.coach && !!this.$user.profile.coach.category;
    }
    setCredentialsMutation(data) {
        this.$credentials = new Credentials(AuthorizationType.USER(), new AuthorizationToken(data.token), Timestamp.add(Timestamp.now(), 1, "month"));
        this.$credentials.saveToLocalStorage();
    }
    logoutMutation() {
        this.$credentials?.cleanFromLocalStorage();
        this.$credentials = null;
    }
    setUserMutation(data) {
        this.$user = data.user;
    }
    async waitForUserLoading() {
        if (!this.$user) {
            await Waiter.wait(500);
            return this.context.dispatch("waitForUserLoading");
        }
        return;
    }
    async login(data) {
        const response = await ApiRequest.json(this.apiRoute.LOGIN, "POST", data);
        this.context.commit(this.SET_CREDENTIALS_MUTATION, { token: response.accessToken });
        this.context.commit(this.SET_USER_MUTATION, { user: response.user });
        return response;
    }
    async logout() {
        this.context.commit(this.LOGOUT_MUTATION);
        this.context.commit(this.SET_USER_MUTATION, { user: null });
    }
    async registerExistedUser(data) {
        const response = await ApiRequest.json(this.apiRoute.REGISTER_EXISTED, "PUT", data);
        this.context.commit(this.SET_CREDENTIALS_MUTATION, { token: response.accessToken });
        this.context.commit(this.SET_USER_MUTATION, { user: response.user });
        return response;
    }
    async sendEmailCode(data) {
        return ApiRequest.json(this.apiRoute.SEND_EMAIL_CODE, "POST", data);
    }
    async verifyEmailCode(data) {
        const response = await ApiRequest.json(this.apiRoute.VERIFY_EMAIL_CODE, "POST", data);
        if (data.login) {
            this.context.commit(this.SET_CREDENTIALS_MUTATION, { token: response.accessToken });
            this.context.commit(this.SET_USER_MUTATION, { user: response.user });
        }
        return response;
    }
    async checkoutUserPassword(data) {
        const qs = ApiRequest.getQueryString(data);
        return ApiRequest.json(this.apiRoute.PASSWORD_CHECKOUT_FACTORY(qs), "GET");
    }
    async isUserExistedBy(data) {
        const qs = ApiRequest.getQueryString(data);
        return await ApiRequest.json(this.apiRoute.EXISTED_BY_FACTORY(qs), "GET");
    }
    async findUserBy(data) {
        const qs = ApiRequest.getQueryString(data);
        return await ApiRequest.json(this.apiRoute.FIND_BY_FACTORY(qs), "GET");
    }
    async getUser() {
        const response = await ApiRequest.json(this.apiRoute.ROOT, "GET");
        this.context.commit(this.SET_USER_MUTATION, { user: response });
        return response;
    }
    // @Action
    // async getProfile(data: { userId: number }) {
    //   const response = await ApiRequest.json(`/user/profile/${data.userId}`, "GET")
    //   this.context.commit(this.SET_PROFILE_MUTATION, { profile: response.data })
    //   return response
    // }
    // @Action
    // async createProfile(data: { profile: IUserProfile }) {
    //   const response = await ApiRequest.json("/user/profile", "POST", { profile: data.profile })
    //   this.context.commit(this.SET_PROFILE_MUTATION, { profile: response })
    //   return response
    // }
    // @Action
    // async updateProfile(data: { profile: IUserProfile }) {
    //   const response = await ApiRequest.json("/user/profile", "PUT", { profile: data.profile })
    //   this.context.commit(this.SET_PROFILE_MUTATION, { profile: response })
    //   return response
    // }
    async updateRequest(data) {
        return ApiRequest.json(this.apiRoute.UPDATE_REQUEST, "POST", data);
    }
    async updateUser(data) {
        const response = await ApiRequest.json(this.apiRoute.ROOT, "PUT", data);
        this.context.commit(this.SET_USER_MUTATION, { user: response });
        return response;
    }
    async uploadAvatar(data) {
        return ApiRequest.upload(this.apiRoute.AVATAR, data.fd);
    }
    async getRelatedRegionProfiledUsers() {
        return ApiRequest.json(this.apiRoute.RELATED_REGION_PROFILED, "GET");
    }
    async getRelatedRegionUsers() {
        return ApiRequest.json(this.apiRoute.RELATED_REGION, "GET");
    }
};
UserModule.types = {
    getters: {
        CREDENTIALS: "User/credentials",
        USER: "User/user",
        PROFILE: "User/profile",
        iS_COACH: "User/isCoach",
    },
    actions: {
        LOGIN: "User/login",
        LOGOUT: "User/logout",
        REGISTER_EXISTED_USER: "User/registerExistedUser",
        SEND_EMAIL_CODE: "User/sendEmailCode",
        VERIFY_EMAIL_CODE: "User/verifyEmailCode",
        CHECKOUT_USER_PASSWORD: "User/checkoutUserPassword",
        IS_USER_EXISTED_BY: "User/isUserExistedBy",
        FIND_USER_BY: "User/findUserBy",
        GET_USER: "User/getUser",
        UPDATE_REQUEST: "User/updateRequest",
        UPDATE_USER: "User/updateUser",
        WAIT_FOR_USER_LOADING: "User/waitForUserLoading",
        UPLOAD_AVATAR: "User/uploadAvatar",
        GET_RELATED_REGION_PROFILED_USERS: "User/getRelatedRegionProfiledUsers",
        GET_RELATED_REGION_USERS: "User/getRelatedRegionUsers",
    },
};
__decorate([
    Mutation
], UserModule.prototype, "setCredentialsMutation", null);
__decorate([
    Mutation
], UserModule.prototype, "logoutMutation", null);
__decorate([
    Mutation
], UserModule.prototype, "setUserMutation", null);
__decorate([
    Action
], UserModule.prototype, "waitForUserLoading", null);
__decorate([
    Action
], UserModule.prototype, "login", null);
__decorate([
    Action
], UserModule.prototype, "logout", null);
__decorate([
    Action
], UserModule.prototype, "registerExistedUser", null);
__decorate([
    Action
], UserModule.prototype, "sendEmailCode", null);
__decorate([
    Action
], UserModule.prototype, "verifyEmailCode", null);
__decorate([
    Action
], UserModule.prototype, "checkoutUserPassword", null);
__decorate([
    Action
], UserModule.prototype, "isUserExistedBy", null);
__decorate([
    Action
], UserModule.prototype, "findUserBy", null);
__decorate([
    Action
], UserModule.prototype, "getUser", null);
__decorate([
    Action
], UserModule.prototype, "updateRequest", null);
__decorate([
    Action
], UserModule.prototype, "updateUser", null);
__decorate([
    Action
], UserModule.prototype, "uploadAvatar", null);
__decorate([
    Action
], UserModule.prototype, "getRelatedRegionProfiledUsers", null);
__decorate([
    Action
], UserModule.prototype, "getRelatedRegionUsers", null);
UserModule = __decorate([
    Module({ namespaced: true })
], UserModule);
export default UserModule;
