import TTLMap from "@/helpers/TTLMap";
import Vue from "vue";
export default class Notificator {
    constructor() {
        this.#messages = new TTLMap();
        if (Notificator.singleton)
            return Notificator.singleton;
        Notificator.singleton = this;
    }
    #messages;
    notify(options) {
        Vue.notify(options);
    }
    notifyDeduplicated(options, ttl = 5000) {
        const message = options.title + options.text;
        if (this.#messages.has(message))
            return;
        this.#messages.set(message, true, ttl);
        Vue.notify(options);
    }
}
Notificator.singleton = null;
