import { __decorate } from "tslib";
import Waiter from "@/helpers/Waiter";
import ApiRequest from "../../shared/ApiRequest";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
let HandbookModule = class HandbookModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.localActions = {
            GET_HANDBOOK: "getHandbook",
            ADD_ADDRESS: "addAddress",
            GET_DISTRICTS: "getDistricts",
            GET_REGIONS: "getRegions",
            GET_CITIES: "getCities",
            GET_EVENT_AGE_CATEGORIES: "getEventAgeCategories",
            GET_EVENT_CATEGORIES: "getEventCategories",
            GET_EVENT_DISCIPLINES: "getEventDisciplines",
            GET_EVENT_DISCIPLINES_GROUPS: "getEventDisciplinesGroups",
            GET_EVENT_REGIONAL_STATUSES: "getEventRegionalStatuses",
            GET_EVENT_TYPES: "getEventTypes",
            GET_AMPLUA: "getAmplua",
            GET_SPORTS_CATEGORY: "getSportsCategory",
            UPLOAD_DOCUMENTS: "uploadDocuments",
            WAIT_FOR_HANDBOOK_LOAD: "waitForHandbookLoading",
        };
        this.apiRoute = {
            ADDRESSES: "/handbook/addresses",
            DISTRICTS: "/handbook/districts",
            REGIONS: "/handbook/regions",
            CITIES: "/handbook/cities",
            EVENT_AGE_CATEGORIES: "/handbook/event-age-categories",
            EVENT_CATEGORIES: "/handbook/event-categories",
            EVENT_DISCIPLINES: "/handbook/event-disciplines",
            EVENT_DISCIPLINES_GROUPS: "/handbook/event-disciplines-groups",
            EVENT_REGIONAL_STATUSES: "/handbook/event-regional-statuses",
            EVENT_TYPES: "/handbook/event-types",
            AMPLUA: "/handbook/amplua",
            SPORTS_CATEGORY: "/handbook/sports-category",
            DOCUMENTS: "/handbook/documents",
        };
        this.$districts = [];
        this.$regions = [];
        this.$cities = [];
        this.$eventRegionalStatuses = [];
        this.$eventTypes = [];
        this.$eventAgeCategories = [];
        this.$eventCategories = [];
        this.$eventDisciplines = [];
        this.$eventDisciplinesGroups = [];
        this.$amplua = [];
        this.$sportsCategory = [];
        this.$isLoaded = false;
        this.MARK_AS_LOADED_MUTATION = "markAsLoaded";
        this.SET_DISTRICTRS_MUTATION = "setDistrictsMutation";
        this.SET_REGIONS_MUTATION = "setRegionsMutation";
        this.SET_CITIES_MUTATION = "setCitiesMutation";
        this.SET_EVENT_REGIONAL_STATUSES_MUTATION = "setEventRegionalStatusesMutation";
        this.SET_EVENT_TYPES_MUTATION = "setEventTypesMutation";
        this.SET_EVENT_AGE_CATEGORIES_MUTATION = "setEventAgeCategoriesMutation";
        this.SET_EVENT_CATEGORIES_MUTATION = "setEventCategoriesMutation";
        this.SET_EVENT_DISCIPLINES_MUTATION = "setEventDisciplinesMutation";
        this.SET_EVENT_DISCIPLINES_GROUPS_MUTATION = "setEventDisciplinesGroupsMutation";
        this.SET_AMPLUA_MUTATION = "setAmpluaMutation";
        this.SET_SPORTS_CATEGORY_MUTATION = "setSportsCategoryMutation";
    }
    get districts() {
        return this.$districts;
    }
    get regions() {
        return this.$regions.sort((a, b) => a.name.localeCompare(b.name));
    }
    get cities() {
        return this.$cities.sort((a, b) => a.name.localeCompare(b.name));
    }
    get eventRegionalStatuses() {
        return this.$eventRegionalStatuses;
    }
    get eventTypes() {
        return this.$eventTypes;
    }
    get eventAgeCategories() {
        return this.$eventAgeCategories;
    }
    get eventCategories() {
        return this.$eventCategories;
    }
    get eventDisciplines() {
        return this.$eventDisciplines;
    }
    get eventDisciplinesGroups() {
        return this.$eventDisciplinesGroups;
    }
    get amplua() {
        return this.$amplua;
    }
    get sportsCategory() {
        return this.$sportsCategory;
    }
    markAsLoaded() {
        this.$isLoaded = true;
    }
    setDistrictsMutation(data) {
        this.$districts = data.districts;
    }
    setRegionsMutation(data) {
        this.$regions = data.regions;
    }
    setCitiesMutation(data) {
        this.$cities = data.cities;
    }
    setEventRegionalStatusesMutation(data) {
        this.$eventRegionalStatuses = data.eventRegionalStatuses;
    }
    setEventTypesMutation(data) {
        this.$eventTypes = data.eventTypes;
    }
    setEventAgeCategoriesMutation(data) {
        this.$eventAgeCategories = data.eventAgeCategories;
    }
    setEventCategoriesMutation(data) {
        this.$eventCategories = data.eventCategories;
    }
    setEventDisciplinesMutation(data) {
        this.$eventDisciplines = data.eventDisciplines;
    }
    setEventDisciplinesGroupsMutation(data) {
        this.$eventDisciplinesGroups = data.eventDisciplinesGroups;
    }
    setAmpluaMutation(data) {
        this.$amplua = data.amplua;
    }
    setSportsCategoryMutation(data) {
        this.$sportsCategory = data.sportsCategory;
    }
    async getHandbook() {
        await Promise.all([
            this.context.dispatch(this.localActions.GET_DISTRICTS),
            this.context.dispatch(this.localActions.GET_REGIONS),
            this.context.dispatch(this.localActions.GET_CITIES),
            this.context.dispatch(this.localActions.GET_EVENT_AGE_CATEGORIES),
            this.context.dispatch(this.localActions.GET_EVENT_CATEGORIES),
            this.context.dispatch(this.localActions.GET_EVENT_DISCIPLINES),
            this.context.dispatch(this.localActions.GET_EVENT_DISCIPLINES_GROUPS),
            this.context.dispatch(this.localActions.GET_EVENT_REGIONAL_STATUSES),
            this.context.dispatch(this.localActions.GET_EVENT_TYPES),
            this.context.dispatch(this.localActions.GET_AMPLUA),
            this.context.dispatch(this.localActions.GET_SPORTS_CATEGORY),
        ]);
        this.context.commit(this.MARK_AS_LOADED_MUTATION);
    }
    async waitForHandbookLoading() {
        if (!this.$isLoaded) {
            await Waiter.wait(500);
            return this.context.dispatch(this.localActions.WAIT_FOR_HANDBOOK_LOAD);
        }
        return;
    }
    async addAddress(data) {
        return ApiRequest.json(this.apiRoute.ADDRESSES, "POST", data);
    }
    async getDistricts() {
        const response = await ApiRequest.json(this.apiRoute.DISTRICTS, "GET");
        this.context.commit(this.SET_DISTRICTRS_MUTATION, { districts: response });
        return response;
    }
    async getRegions() {
        const response = await ApiRequest.json(this.apiRoute.REGIONS, "GET");
        this.context.commit(this.SET_REGIONS_MUTATION, { regions: response });
        return response;
    }
    async getCities() {
        const response = await ApiRequest.json(this.apiRoute.CITIES, "GET");
        this.context.commit(this.SET_CITIES_MUTATION, { cities: response });
        return response;
    }
    async getEventAgeCategories() {
        const response = await ApiRequest.json(this.apiRoute.EVENT_AGE_CATEGORIES, "GET");
        this.context.commit(this.SET_EVENT_AGE_CATEGORIES_MUTATION, { eventAgeCategories: response });
        return response;
    }
    async getEventCategories() {
        const response = await ApiRequest.json(this.apiRoute.EVENT_CATEGORIES, "GET");
        this.context.commit(this.SET_EVENT_CATEGORIES_MUTATION, { eventCategories: response });
        return response;
    }
    async getEventDisciplines() {
        const response = await ApiRequest.json(this.apiRoute.EVENT_DISCIPLINES, "GET");
        this.context.commit(this.SET_EVENT_DISCIPLINES_MUTATION, { eventDisciplines: response });
        return response;
    }
    async getEventDisciplinesGroups() {
        const response = await ApiRequest.json(this.apiRoute.EVENT_DISCIPLINES_GROUPS, "GET");
        this.context.commit(this.SET_EVENT_DISCIPLINES_GROUPS_MUTATION, { eventDisciplinesGroups: response });
        return response;
    }
    async getEventRegionalStatuses() {
        const response = await ApiRequest.json(this.apiRoute.EVENT_REGIONAL_STATUSES, "GET");
        this.context.commit(this.SET_EVENT_REGIONAL_STATUSES_MUTATION, { eventRegionalStatuses: response });
        return response;
    }
    async getEventTypes() {
        const response = await ApiRequest.json(this.apiRoute.EVENT_TYPES, "GET");
        this.context.commit(this.SET_EVENT_TYPES_MUTATION, { eventTypes: response });
        return response;
    }
    async getAmplua() {
        const response = await ApiRequest.json(this.apiRoute.AMPLUA, "GET");
        this.context.commit(this.SET_AMPLUA_MUTATION, { amplua: response });
        return response;
    }
    async getSportsCategory() {
        const response = await ApiRequest.json(this.apiRoute.SPORTS_CATEGORY, "GET");
        this.context.commit(this.SET_SPORTS_CATEGORY_MUTATION, { sportsCategory: response });
        return response;
    }
    async uploadDocuments(data) {
        return ApiRequest.upload(this.apiRoute.DOCUMENTS, data.fd);
    }
};
HandbookModule.types = {
    getters: {
        DISTRICTS: "Handbook/districts",
        REGIONS: "Handbook/regions",
        CITIES: "Handbook/cities",
        EVENT_REGIONAL_STATUSES: "Handbook/eventRegionalStatuses",
        EVENT_TYPES: "Handbook/eventTypes",
        EVENT_AGE_CATEGORIES: "Handbook/eventAgeCategories",
        EVENT_CATEGORIES: "Handbook/eventCategories",
        EVENT_DISCIPLINES: "Handbook/eventDisciplines",
        EVENT_DISCIPLINES_GROUPS: "Handbook/eventDisciplinesGroups",
        AMPLUA: "Handbook/amplua",
        SPORTS_CATEGORY: "Handbook/sportsCategory",
    },
    actions: {
        GET_HANDBOOK: "Handbook/getHandbook",
        ADD_ADDRESS: "Handbook/addAddress",
        GET_DISTRICTS: "Handbook/getDistricts",
        GET_REGIONS: "Handbook/getRegions",
        GET_CITIES: "Handbook/getCities",
        GET_EVENT_AGE_CATEGORIES: "Handbook/getEventAgeCategories",
        GET_EVENT_CATEGORIES: "Handbook/getEventCategories",
        GET_EVENT_DISCIPLINES: "Handbook/getEventDisciplines",
        GET_EVENT_DISCIPLINES_GROUPS: "Handbook/getEventDisciplinesGroups",
        GET_EVENT_REGIONAL_STATUSES: "Handbook/getEventRegionalStatuses",
        GET_EVENT_TYPES: "Handbook/getEventTypes",
        GET_AMPLUA: "Handbook/getAmplua",
        GET_SPORTS_CATEGORY: "Handbook/getSportsCategory",
        UPLOAD_DOCUMENTS: "Handbook/uploadDocuments",
        WAIT_FOR_HANDBOOK_LOAD: "Handbook/waitForHandbookLoading",
    },
};
__decorate([
    Mutation
], HandbookModule.prototype, "markAsLoaded", null);
__decorate([
    Mutation
], HandbookModule.prototype, "setDistrictsMutation", null);
__decorate([
    Mutation
], HandbookModule.prototype, "setRegionsMutation", null);
__decorate([
    Mutation
], HandbookModule.prototype, "setCitiesMutation", null);
__decorate([
    Mutation
], HandbookModule.prototype, "setEventRegionalStatusesMutation", null);
__decorate([
    Mutation
], HandbookModule.prototype, "setEventTypesMutation", null);
__decorate([
    Mutation
], HandbookModule.prototype, "setEventAgeCategoriesMutation", null);
__decorate([
    Mutation
], HandbookModule.prototype, "setEventCategoriesMutation", null);
__decorate([
    Mutation
], HandbookModule.prototype, "setEventDisciplinesMutation", null);
__decorate([
    Mutation
], HandbookModule.prototype, "setEventDisciplinesGroupsMutation", null);
__decorate([
    Mutation
], HandbookModule.prototype, "setAmpluaMutation", null);
__decorate([
    Mutation
], HandbookModule.prototype, "setSportsCategoryMutation", null);
__decorate([
    Action
], HandbookModule.prototype, "getHandbook", null);
__decorate([
    Action
], HandbookModule.prototype, "waitForHandbookLoading", null);
__decorate([
    Action
], HandbookModule.prototype, "addAddress", null);
__decorate([
    Action
], HandbookModule.prototype, "getDistricts", null);
__decorate([
    Action
], HandbookModule.prototype, "getRegions", null);
__decorate([
    Action
], HandbookModule.prototype, "getCities", null);
__decorate([
    Action
], HandbookModule.prototype, "getEventAgeCategories", null);
__decorate([
    Action
], HandbookModule.prototype, "getEventCategories", null);
__decorate([
    Action
], HandbookModule.prototype, "getEventDisciplines", null);
__decorate([
    Action
], HandbookModule.prototype, "getEventDisciplinesGroups", null);
__decorate([
    Action
], HandbookModule.prototype, "getEventRegionalStatuses", null);
__decorate([
    Action
], HandbookModule.prototype, "getEventTypes", null);
__decorate([
    Action
], HandbookModule.prototype, "getAmplua", null);
__decorate([
    Action
], HandbookModule.prototype, "getSportsCategory", null);
__decorate([
    Action
], HandbookModule.prototype, "uploadDocuments", null);
HandbookModule = __decorate([
    Module({ namespaced: true })
], HandbookModule);
export default HandbookModule;
