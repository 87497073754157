import InvalidArgumentError from "../error/InvalidArgumentError";
export default class ValueObject {
    constructor(value) {
        this.value = value;
        this.ensureValueIsDefined();
    }
    ensureValueIsDefined() {
        if (this.value === null || this.value === undefined) {
            throw new InvalidArgumentError("Value must be defined");
        }
    }
    equals(other) {
        return other.constructor.name === this.constructor.name && other.value === this.value;
    }
    notEquals(other) {
        return !this.equals(other);
    }
    toString() {
        return this.value.toString();
    }
}
