export default class DaDataSuggestions {
    constructor() {
        this.apiUrl = `https://suggestions.dadata.ru`;
        this.token = "15b872ff7317194d97ff6e56cb16a2c798112884";
        this.cache = {
            authority: new Map(),
            address: new Map(),
            company: new Map(),
        };
        if (DaDataSuggestions.singleton)
            return DaDataSuggestions.singleton;
        DaDataSuggestions.singleton = this;
    }
    loadFromCache(type, query) {
        if (!this.cache[type].has(query))
            return;
        const cacheItem = this.cache[type].get(query);
        const diff = Date.now() - cacheItem.lastRequest.getTime();
        if (diff < 60000 * 15) {
            return cacheItem.data;
        }
    }
    async apiRequest(method, query) {
        const response = await fetch(`${this.apiUrl}/${method}`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "Token " + this.token,
            },
            body: JSON.stringify({ query }),
        });
        return await response.json();
    }
    async getAuthorities(query) {
        const fromCache = this.loadFromCache("authority", query);
        if (fromCache)
            return fromCache;
        const method = "suggestions/api/4_1/rs/suggest/fms_unit";
        const data = await this.apiRequest(method, query);
        this.cache.authority.set(query, { data, lastRequest: new Date() });
        return data;
    }
    async getAddresses(query) {
        const fromCache = this.loadFromCache("address", query);
        if (fromCache)
            return fromCache;
        const method = `suggestions/api/4_1/rs/suggest/address`;
        const data = await this.apiRequest(method, query);
        this.cache.address.set(query, { data, lastRequest: new Date() });
        return data;
    }
    async getCompanies(query) {
        const fromCache = this.loadFromCache("company", query);
        if (fromCache)
            return fromCache;
        const method = `suggestions/api/4_1/rs/suggest/party`;
        const data = await this.apiRequest(method, query);
        this.cache.company.set(query, { data, lastRequest: new Date() });
        return data;
    }
}
