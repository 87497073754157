import Vue from "vue";
import store from "./store";
import router from "./router";
import VueNotification from "vue-notification";
import VueTelInput from "vue-tel-input";
import VueTheMask from "vue-the-mask";
import "vue-tel-input/dist/vue-tel-input.css";
import vuetify from "./plugins/vuetify";
import DaDataSuggestions from "./service/DaDataSuggestions";
import App from "./App.vue";
import "./assets/css/mdi.min.css";
Vue.config.productionTip = false;
Vue.use(VueTheMask);
Vue.use(VueNotification);
Vue.use(VueTelInput);
new DaDataSuggestions();
new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
