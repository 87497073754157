import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "./routes";
import { ONLY_ADMIN, ONLY_AUTHORIZED, ONLY_USER, routePath } from "./router-constants";
import Credentials from "@/store/shared/Credentials";
import AuthorizationType from "@/store/shared/value-object/AuthorizationType";
Vue.use(VueRouter);
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
router.beforeEach(async (to, from, next) => {
    console.log(to);
    if (to.path === "/myadmin")
        return next(routePath.LOGIN);
    const credentials = Credentials.fromLocalStorage();
    const isAuthorized = !!credentials;
    if (!isAuthorized) {
        if (to.meta[ONLY_AUTHORIZED]) {
            return next(routePath.LOGIN);
        }
        return next();
    }
    const isAdmin = credentials.type.equals(AuthorizationType.ADMIN());
    const isUser = credentials.type.equals(AuthorizationType.USER());
    if (to.path === routePath.LOGIN) {
        return next(routePath.ROOT);
    }
    const isToRootByUser = to.path === routePath.ROOT && isUser;
    const isToRootByAdmin = to.path === routePath.ROOT && isAdmin;
    if ((to.meta[ONLY_ADMIN] && !isAdmin) || isToRootByUser) {
        return next(routePath.user.ROOT);
    }
    if ((to.meta[ONLY_USER] && !isUser) || isToRootByAdmin) {
        return next(routePath.admin.USERS);
    }
    return next();
});
export default router;
