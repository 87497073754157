import StringValueObject from "@/shared/domain/value-object/StringValueObject";
export default class AuthorizationType extends StringValueObject {
    constructor(value) {
        super(value);
        this.value = value;
        this.ensureIsValidValue();
    }
    ensureIsValidValue() {
        if (!AuthorizationType.availableValues.includes(this.value)) {
            throw new Error(`<${this.constructor.name}> Invalid AuthorizationType value. Passed: ${this.value}. Available: ${AuthorizationType.availableValues.join(", ")}`);
        }
    }
}
AuthorizationType.availableValues = ["user", "admin"];
AuthorizationType.USER_VALUE = "user";
AuthorizationType.USER = () => new AuthorizationType(AuthorizationType.USER_VALUE);
AuthorizationType.ADMIN_VALUE = "admin";
AuthorizationType.ADMIN = () => new AuthorizationType(AuthorizationType.ADMIN_VALUE);
