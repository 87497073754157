import MaxStringLengthExceeded from "../error/MaxStringLengthExceeded";
import ValueObject from "./ValueObject";
export default class StringValueObject extends ValueObject {
    constructor(value, maxLength = Infinity) {
        super(value);
        this.value = value;
        this.maxLength = maxLength;
        this.ensureIsValid();
    }
    ensureIsValid() {
        if (this.value.length > this.maxLength) {
            throw new MaxStringLengthExceeded(`<${this.constructor.name}> max length is ${this.maxLength}`);
        }
    }
    toString() {
        return this.value;
    }
}
