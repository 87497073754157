import { __decorate } from "tslib";
import ApiRequest from "../../shared/ApiRequest";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import moment from "moment-timezone";
function sortUsers(users) {
    const usersByCategory = {
        any: users,
        active: [],
        inactive: [],
        suspended: [],
        junior: [],
        honorary: [],
        premoderated: [],
        lk: [],
        nationalTeam: [],
    };
    const table = {};
    for (let user of users) {
        if (Object.prototype.hasOwnProperty.call(table, user.id) === false) {
            table[user.id] = user;
        }
        const isJunior = moment(user.birthdate).toDate().getTime() + 567648000000 > Date.now();
        const isExpiried = moment(user.expiryDate).isBefore(moment());
        const notStoppedAndNotPremoderated = user.isStopped === false && user.isPremoderated === false;
        if (isJunior === false && notStoppedAndNotPremoderated && user.isHonorary === false && isExpiried === false) {
            usersByCategory.active.push(user);
        }
        else if (isJunior === true && notStoppedAndNotPremoderated && user.isHonorary === false && isExpiried === false) {
            usersByCategory.junior.push(user);
        }
        else if (isJunior === false && notStoppedAndNotPremoderated && user.isHonorary === true) {
            usersByCategory.honorary.push(user);
        }
        else if (notStoppedAndNotPremoderated && isExpiried) {
            usersByCategory.inactive.push(user);
        }
        else if (user.isStopped === true && user.isPremoderated === false) {
            usersByCategory.suspended.push(user);
        }
        else if (user.isStopped === false && user.isPremoderated === true) {
            usersByCategory.premoderated.push(user);
        }
        if (user.isNationalTeamMember) {
            usersByCategory.nationalTeam.push(user);
        }
        if (user.updateRequest) {
            usersByCategory.lk.push(user);
        }
    }
    return {
        usersByCategory,
        table,
    };
}
let UsersModule = class UsersModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.apiRoute = {
            ROOT_FACTORY: (qs) => `/users?${qs}`,
            ROOT_ID_FACTORY: (id) => `/users/${id}`,
            ROOT: `/users`,
            EXPORT_EXCEL: "/users/export-excel",
            UPDATE_REQUEST: "/users/update-request",
            AVATAR: "/users/avatar",
        };
        this.$usersTable = {};
        this.$users = {
            any: [],
            active: [],
            inactive: [],
            suspended: [],
            junior: [],
            honorary: [],
            premoderated: [],
            lk: [],
            nationalTeam: [],
        };
        this.UPDATE_USER_DIRECTLY_MUTATION = "updateUserDirectlyMutation";
        this.APPEND_USER_MUTATION = "appendUserMutation";
        this.DELETE_USER_BY_ID_MUTATION = "deleteUserByIdMutation";
        this.REMOVE_USER_UPDATE_REQUEST_MUTATION = "removeUserUpdateRequestMutation";
        this.SET_USERS_MUTATION = "setUsersMutation";
    }
    get usersByCategories() {
        return this.$users;
    }
    get usersTable() {
        return this.$usersTable;
    }
    updateUserDirectlyMutation(data) {
        const { user } = data;
        if (Object.prototype.hasOwnProperty.call(this.$usersTable, user.id) === false) {
            this.$usersTable[user.id] = {};
        }
        this.$usersTable[user.id].idRfsl = user.idRfsl;
        this.$usersTable[user.id].name = user.name;
        this.$usersTable[user.id].surname = user.surname;
        this.$usersTable[user.id].patronymic = user.patronymic;
        this.$usersTable[user.id].birthdate = moment(user.birthdate).toDate();
        this.$usersTable[user.id].gender = user.gender;
        this.$usersTable[user.id].phone = user.phone;
        this.$usersTable[user.id].email = user.email;
        this.$usersTable[user.id].addressId = user.addressId;
        this.$usersTable[user.id].avatar = user.avatar;
        this.$usersTable[user.id].entryDate = moment(user.entryDate).toDate();
        this.$usersTable[user.id].expiryDate = moment(user.expiryDate).toDate();
        this.$usersTable[user.id].isStopped = user.isStopped;
        this.$usersTable[user.id].isHonorary = user.isHonorary;
        this.$usersTable[user.id].isPremoderated = user.isPremoderated;
        if (user.hasOwnProperty("sportsCategory") && user.sportsCategory !== null) {
            this.$usersTable[user.id].sportsCategory = user.sportsCategory;
        }
        if (this.$usersTable[user.id].hasOwnProperty("address") &&
            this.$usersTable[user.id].address !== null &&
            user.hasOwnProperty("address") &&
            user.address !== null) {
            this.$usersTable[user.id].address.id = user.address.id;
            this.$usersTable[user.id].address.cityId = user.address.cityId;
            this.$usersTable[user.id].address.regionId = user.address.regionId;
        }
        this.$usersTable[user.id].updateRequest = user.updateRequest;
        const { table, usersByCategory } = sortUsers(this.$users.any);
        this.$usersTable = table;
        this.$users = usersByCategory;
    }
    appendUserMutation(data) {
        const { user } = data;
        this.$users.premoderated.push(user);
        this.$usersTable[user.id] = user;
    }
    deleteUserByIdMutation(data) {
        const { id } = data;
        delete this.$usersTable[id];
        for (let category in this.$users) {
            const userIndex = this.$users[category].findIndex((item) => item.id === id);
            if (userIndex !== -1) {
                this.$users[category].splice(userIndex, 1);
            }
        }
    }
    removeUserUpdateRequestMutation(data) {
        const { userId } = data;
        this.$usersTable[userId].updateRequest = null;
        const index = this.$users.lk.findIndex((u) => u.id === userId);
        this.$users.lk.splice(index, 1);
    }
    setUsersMutation(data) {
        const { users } = data;
        const { table, usersByCategory } = sortUsers(users);
        this.$usersTable = table;
        this.$users = usersByCategory;
    }
    async getUsers(data = {}) {
        const qs = ApiRequest.getQueryString(data);
        const response = await ApiRequest.json(this.apiRoute.ROOT_FACTORY(qs), "GET");
        this.context.commit(this.SET_USERS_MUTATION, { users: response });
        return response;
    }
    async loadUser(data) {
        return ApiRequest.json(this.apiRoute.ROOT_ID_FACTORY(data.id), "GET");
    }
    async addUser(data) {
        const response = await ApiRequest.json(this.apiRoute.ROOT, "POST", data);
        this.context.commit(this.APPEND_USER_MUTATION, { user: response });
        return response;
    }
    async updateUser(data) {
        const response = await ApiRequest.json(this.apiRoute.ROOT_ID_FACTORY(data.id), "PUT", { user: data.user });
        this.context.commit(this.UPDATE_USER_DIRECTLY_MUTATION, { user: response });
        return response;
    }
    async deleteUser(data) {
        const response = await ApiRequest.json(this.apiRoute.ROOT_ID_FACTORY(data.id), "DELETE");
        this.context.commit(this.DELETE_USER_BY_ID_MUTATION, data);
        return response;
    }
    async excelExport(data) {
        await ApiRequest.download(this.apiRoute.EXPORT_EXCEL, data, `users-${data.category}.xlsx`);
    }
    async approveUserUpdateRequest(data) {
        const response = await ApiRequest.json(this.apiRoute.UPDATE_REQUEST, "PUT", {
            requestId: data.requestId,
        });
        this.context.commit(this.REMOVE_USER_UPDATE_REQUEST_MUTATION, data);
        return response;
    }
    async rejectUserUpdateRequest(data) {
        const response = await ApiRequest.json(this.apiRoute.UPDATE_REQUEST, "DELETE", {
            requestId: data.requestId,
            rejectReason: data.rejectReason,
        });
        this.context.commit(this.REMOVE_USER_UPDATE_REQUEST_MUTATION, data);
        return response;
    }
    async uploadAvatar(data) {
        return ApiRequest.upload(this.apiRoute.AVATAR, data.fd);
    }
};
UsersModule.types = {
    getters: {
        USERS_BY_CATEGORIES: "Users/usersByCategories",
        USERS_TABLE: "Users/usersTable",
    },
    actions: {
        GET_USERS: "Users/getUsers",
        LOAD_USER: "Users/loadUser",
        ADD_USER: "Users/addUser",
        UPDATE_USER: "Users/updateUser",
        DELETE_USER: "Users/deleteUser",
        EXCEL_EXPORT: "Users/excelExport",
        APPROVE_USER_UPDATE_REQUEST: "Users/approveUserUpdateRequest",
        REJECT_USER_UPDATE_REQUEST: "Users/rejectUserUpdateRequest",
        UPLOAD_AVATAR: "Users/uploadAvatar",
    },
};
__decorate([
    Mutation
], UsersModule.prototype, "updateUserDirectlyMutation", null);
__decorate([
    Mutation
], UsersModule.prototype, "appendUserMutation", null);
__decorate([
    Mutation
], UsersModule.prototype, "deleteUserByIdMutation", null);
__decorate([
    Mutation
], UsersModule.prototype, "removeUserUpdateRequestMutation", null);
__decorate([
    Mutation
], UsersModule.prototype, "setUsersMutation", null);
__decorate([
    Action
], UsersModule.prototype, "getUsers", null);
__decorate([
    Action
], UsersModule.prototype, "loadUser", null);
__decorate([
    Action
], UsersModule.prototype, "addUser", null);
__decorate([
    Action
], UsersModule.prototype, "updateUser", null);
__decorate([
    Action
], UsersModule.prototype, "deleteUser", null);
__decorate([
    Action
], UsersModule.prototype, "excelExport", null);
__decorate([
    Action
], UsersModule.prototype, "approveUserUpdateRequest", null);
__decorate([
    Action
], UsersModule.prototype, "rejectUserUpdateRequest", null);
__decorate([
    Action
], UsersModule.prototype, "uploadAvatar", null);
UsersModule = __decorate([
    Module({ namespaced: true })
], UsersModule);
export default UsersModule;
